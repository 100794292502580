import React from 'react';

import CreateDraft from './CreateDraft';
import CustomModal from '../../../RiverusUI/Components/CustomModal';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  setRefreshKey: any;
  setFilters: any;
  setSorting: any;
  initialFilter: any;
}

const CreateDraftDialog: React.FC<Props> = ({
  open,
  onClose,
  setRefreshKey,
  setFilters,
  setSorting,
  initialFilter,
}) => {
  return (
    <CustomModal
      maxWidth="md"
      title="Create Draft"
      open={open}
      handleClose={onClose}
    >
      <CreateDraft
        onClose={onClose}
        setRefreshKey={setRefreshKey}
        setFilters={setFilters}
        setSorting={setSorting}
        initialFilter={initialFilter}
      />
    </CustomModal>
  );
};

export default CreateDraftDialog;
